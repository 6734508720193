import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { graphql } from '@octokit/graphql';
import { formatDistanceToNow, addDays } from 'date-fns';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #0d1117;
  color: #c9d1d9;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
`;

const LoginButton = styled.button`
  background-color: #238636;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #2ea043;
    transform: translateY(-1px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const PRSection = styled.div`
  margin: 24px 0;
  background-color: #161b22;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: visible;
  position: relative;

  h2 {
    color: #c9d1d9;
    border-bottom: 1px solid #30363d;
    padding-bottom: 12px;
    margin-top: 0;
    font-size: 1.4rem;
  }
`;

const CollapsibleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  user-select: none;
  padding: 4px 0;
  transition: opacity 0.2s ease;

  h2 {
    margin: 0;
    border-bottom: none;
    padding-bottom: 0;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const Caret = styled.span`
  display: inline-block;
  transition: transform 0.2s;
  transform: ${props => props['data-expanded'] === 'true' ? 'rotate(90deg)' : 'none'};
  font-size: 12px;
`;

const CollapsibleContent = styled.div`
  margin-top: 12px;
  display: ${props => props['data-expanded'] === 'true' ? 'block' : 'none'};
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border-radius: 8px;
  margin: 0;
  position: relative;

  /* Add smooth scrolling */
  scroll-behavior: smooth;

  /* Hide scrollbar for cleaner look */
  scrollbar-width: thin;

  /* Style webkit scrollbar */
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1c2128;
    border-radius: 0 0 8px 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #30363d;
    border-radius: 4px;

    &:hover {
      background: #3f444c;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;

  background-color: #161b22;
`;

const Th = styled.th`
  text-align: left;
  padding: 14px;
  background-color: #1c2128;
  border-bottom: 2px solid #30363d;
  color: #c9d1d9;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
`;

const Td = styled.td`
  padding: 14px;
  border-bottom: 1px solid #21262d;
  font-size: 14px;
  color: #c9d1d9;
  transition: background-color 0.2s ease;

  &:first-child {
    font-weight: 500;
  }
`;

const PRLink = styled.a`
  color: #58a6ff;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    color: #79c0ff;
    text-decoration: none;
    transform: translateX(2px);
  }
`;

const PRAuthor = styled.span`
  color: #8b949e;
`;

const CommentCount = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #8b949e;
`;

const SortableHeader = styled(Th)`
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-right: 24px;

  &[data-sort-direction='asc']:after {
    content: '↑';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #8b949e;
  }

  &[data-sort-direction='desc']:after {
    content: '↓';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #8b949e;
  }

  &:hover {
    background-color: #1c2129;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(35, 134, 54, 0.2);
  border-radius: 50%;
  border-top: 3px solid #238636;
  animation: spin 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  color: #8b949e;
  gap: 16px;
  background-color: #161b22;
  border-radius: 10px;
  margin: 24px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  background-color: #161b22;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h1 {
    color: #c9d1d9;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
  }
`;

const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #238636;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 500;

  &:hover:not(:disabled) {
    background-color: #2ea043;
    transform: translateY(-1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &[data-loading='true'] svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const RefreshIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path d="M8 3a5 5 0 0 1 4.546 2.914.5.5 0 0 0 .908-.417A6 6 0 0 0 8 2C5.201 2 2.872 3.757 2.186 6.244a.5.5 0 1 0 .956.291C3.708 4.389 5.67 3 8 3z"/>
    <path d="M8 13a5 5 0 0 1-4.546-2.914.5.5 0 0 0-.908.417A6 6 0 0 0 8 14c2.799 0 5.128-1.757 5.814-4.244a.5.5 0 1 0-.956-.291C12.292 11.611 10.33 13 8 13z"/>
  </svg>
);

const DismissButton = styled.button`
  background-color: #21262d;
  border: 1px solid #30363d;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 12px;
  color: #c9d1d9;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s ease;
  font-weight: 500;
  z-index: 1;

  &:hover {
    background-color: #30363d;
    border-color: #8b949e;
    transform: translateY(-1px);
  }

  &:after {
    content: '▼';
    font-size: 8px;
    display: inline-block;
    margin-top: 1px;
    transition: transform 0.2s ease;
  }

  &:hover:after {
    transform: translateY(1px);
  }

  &[data-open="true"] {
    z-index: 1001;
  }
`;

const DismissDropdownWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  margin-top: 6px;
`;

const DismissDropdown = styled.div`
  background-color: #1c2128;
  border: 1px solid #30363d;
  border-radius: 8px;
  padding: 6px 0;
  min-width: 200px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  transform-origin: top left;
  animation: dropdownAppear 0.2s ease;

  @keyframes dropdownAppear {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const DismissOption = styled.div`
  width: 100%;
  text-align: left;
  padding: 8px 16px;
  background: none;
  border: none;
  color: #c9d1d9;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #238636;
    color: #ffffff;
  }
`;

const RestoreButton = styled(DismissButton)`
  background-color: #238636;
  border: none;
  padding: 6px 12px;

  &:hover {
    background-color: #2ea043;
  }

  &:after {
    content: none;
  }
`;

const EmptyStateMessage = styled.div`
  text-align: center;
  padding: 40px;
  color: #8b949e;
  font-size: 16px;
  background-color: #1c2128;
  border-radius: 8px;
  margin: 16px 0;
  border: 1px dashed #30363d;
`;

// Create a portal component for the dropdown
const DropdownPortal = ({ children, isOpen }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    children,
    document.body
  );
};

function App() {
  const [token, setToken] = useState(localStorage.getItem('github_token'));
  const [prs, setPRs] = useState({
    authored: [],
    directReview: [],
    teamReview: [],
    mentioned: []
  });
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({
    authored: { field: null, direction: null },
    directReview: { field: null, direction: null },
    teamReview: { field: null, direction: null },
    mentioned: { field: null, direction: null }
  });
  const [dismissedPRs, setDismissedPRs] = useState({});
  const [openDismissDropdown, setOpenDismissDropdown] = useState(null);
  const [isDismissedSectionExpanded, setIsDismissedSectionExpanded] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [activeButtonId, setActiveButtonId] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const newToken = urlParams.get('token');

    if (newToken) {
      localStorage.setItem('github_token', newToken);
      setToken(newToken);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    const savedDismissedPRs = localStorage.getItem('dismissedPRs');
    if (savedDismissedPRs) {
      setDismissedPRs(JSON.parse(savedDismissedPRs));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('dismissedPRs', JSON.stringify(dismissedPRs));
  }, [dismissedPRs]);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openDismissDropdown && !event.target.closest('.dismiss-button')) {
        setOpenDismissDropdown(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [openDismissDropdown]);

  // Add scroll and resize event handlers to update dropdown position
  useEffect(() => {
    if (!openDismissDropdown) return;

    const updateDropdownPosition = () => {
      const activeButton = document.querySelector(`.dismiss-button[data-pr-id="${openDismissDropdown}"]`);
      if (activeButton) {
        const buttonRect = activeButton.getBoundingClientRect();

        // Find the parent table container to check for horizontal scrolling
        const tableContainer = activeButton.closest('.table-container');
        let leftOffset = buttonRect.left;

        // If the table is scrolled horizontally, adjust the dropdown position
        if (tableContainer) {
          // Ensure the dropdown doesn't go off-screen to the right
          const viewportWidth = window.innerWidth;
          const dropdownWidth = 200; // Approximate width of dropdown

          if (leftOffset + dropdownWidth > viewportWidth) {
            leftOffset = Math.max(0, viewportWidth - dropdownWidth - 10);
          }
        }

        setDropdownPosition({
          top: buttonRect.bottom,
          left: leftOffset
        });
      }
    };

    // Update position on scroll
    window.addEventListener('scroll', updateDropdownPosition, true);
    // Update position on resize
    window.addEventListener('resize', updateDropdownPosition);

    // Initial position update
    updateDropdownPosition();

    return () => {
      window.removeEventListener('scroll', updateDropdownPosition, true);
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [openDismissDropdown]);

  const fetchPRs = useCallback(async () => {
    setLoading(true);
    const graphqlWithAuth = graphql.defaults({
      headers: {
        authorization: `token ${token}`,
      },
    });

    try {
      const query = `
        query {
          viewer {
            login
            organizations(first: 10) {
              nodes {
                name
                teams(first: 10) {
                  nodes {
                    name
                  }
                }
              }
            }
          }
          authoredPRs: search(query: "is:pr is:open author:@me archived:false", type: ISSUE, first: 100) {
            nodes {
              ... on PullRequest {
                id
                title
                number
                url
                repository {
                  name
                  owner {
                    login
                  }
                }
                author {
                  login
                }
                createdAt
                updatedAt
                comments {
                  totalCount
                }
                reviews {
                  totalCount
                }
                reviewThreads(first: 100) {
                  nodes {
                    isResolved
                  }
                }
              }
            }
          }
          reviewRequestedPRs: search(query: "is:pr is:open review-requested:@me archived:false", type: ISSUE, first: 100) {
            nodes {
              ... on PullRequest {
                id
                title
                number
                url
                repository {
                  name
                  owner {
                    login
                  }
                }
                author {
                  login
                }
                createdAt
                updatedAt
                comments {
                  totalCount
                }
                reviews(first: 10) {
                  nodes {
                    author {
                      login
                    }
                    submittedAt
                    state
                  }
                }
                reviewThreads(first: 100) {
                  nodes {
                    isResolved
                  }
                }
                reviewRequests(first: 10) {
                  nodes {
                    requestedReviewer {
                      ... on Team {
                        name
                      }
                      ... on User {
                        login
                      }
                    }
                  }
                }
              }
            }
          }
          mentionedPRs: search(query: "is:pr is:open mentions:@me -author:@me archived:false", type: ISSUE, first: 100) {
            nodes {
              ... on PullRequest {
                id
                title
                number
                url
                repository {
                  name
                  owner {
                    login
                  }
                }
                author {
                  login
                }
                createdAt
                updatedAt
                comments {
                  totalCount
                }
                reviews {
                  totalCount
                }
                reviewThreads(first: 100) {
                  nodes {
                    isResolved
                  }
                }
              }
            }
          }
        }
      `;

      const result = await graphqlWithAuth(query).catch(e => {
        console.error('GraphQL error:', e);
        return e.data;
      });

      const userTeams = result.viewer.organizations.nodes
        .filter(org => org?.teams)
        .flatMap(org => org?.teams?.nodes?.map(team => team.name));

      const processPRs = (prs) => {
        return prs.map(pr => ({
          ...pr,
          unresolvedThreads: pr.reviewThreads.nodes.filter(thread => !thread.isResolved).length,
          totalComments: (pr.comments?.totalCount || 0) + (pr.reviews?.totalCount || 0)
        }));
      };

      const processReviewPRs = (prs) => {
        const directReview = [];
        const teamReview = [];

        prs.nodes.forEach(pr => {
          const teamNames = pr.reviewRequests.nodes
            .map(request => request.requestedReviewer?.name)
            .filter(name => name);

          const userReview = pr.reviews.nodes
            .filter(review => review.author.login === result.viewer.login)
            .sort((a, b) => new Date(b.submittedAt).getTime() - new Date(a.submittedAt).getTime())[0];

          const lastReview = userReview
            ? `${formatDistanceToNow(new Date(userReview.submittedAt))} ago (${userReview.state.toLowerCase()})`
            : 'Never';

          const prWithReview = {
            ...pr,
            teamNames,
            lastReview,
            lastReviewDate: userReview ? userReview.submittedAt : null
          };

          // Check if user is directly requested for review
          const isDirectlyRequested = pr.reviewRequests.nodes
            .some(request => request.requestedReviewer?.login === result.viewer.login);

          // Check if user's team is requested for review
          const isTeamRequested = teamNames.some(team => userTeams.includes(team));

          if (isDirectlyRequested) {
            directReview.push(prWithReview);
          } else if (isTeamRequested) {
            teamReview.push(prWithReview);
          }
        });

        return { directReview, teamReview };
      };

      const { directReview, teamReview } = processReviewPRs(result.reviewRequestedPRs);

      const authoredPRs = processPRs(result.authoredPRs.nodes);
      const allReviewPRs = [...directReview, ...teamReview];

      const mentionedPRs = processPRs(result.mentionedPRs.nodes).filter(pr =>
        !authoredPRs.some(authored => authored.id === pr.id) &&
        !allReviewPRs.some(reviewed => reviewed.id === pr.id)
      );

      setPRs({
        authored: authoredPRs,
        directReview: processPRs(directReview),
        teamReview: processPRs(teamReview),
        mentioned: mentionedPRs
      });
    } catch (error) {
      console.error('Error fetching PRs:', error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchPRs();
    }
  }, [token, fetchPRs]);

  const handleLogin = () => {
    window.location.href = 'https://github-pr-dashboard-worker.jeremy-7bf.workers.dev/api/auth/github';
  };

  const getSortedPRs = (prs, sortConfig) => {
    if (!sortConfig.field) return prs;

    return [...prs].sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.field) {
        case 'repository':
          aValue = `${a.repository.owner.login}/${a.repository.name}`;
          bValue = `${b.repository.owner.login}/${b.repository.name}`;
          break;
        case 'title':
          aValue = a.title;
          bValue = b.title;
          break;
        case 'author':
          aValue = a.author.login;
          bValue = b.author.login;
          break;
        case 'age':
          aValue = new Date(a.createdAt);
          bValue = new Date(b.createdAt);
          break;
        case 'updated':
          aValue = new Date(a.updatedAt);
          bValue = new Date(b.updatedAt);
          break;
        case 'comments':
          aValue = a.totalComments;
          bValue = b.totalComments;
          break;
        case 'unresolved':
          aValue = a.unresolvedThreads;
          bValue = b.unresolvedThreads;
          break;
        case 'lastReview':
          aValue = a.lastReviewDate ? new Date(a.lastReviewDate) : new Date(0);
          bValue = b.lastReviewDate ? new Date(b.lastReviewDate) : new Date(0);
          break;
        default:
          return 0;
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (section, field) => {
    setSorting(prev => {
      const newSorting = { ...prev };
      if (prev[section].field === field) {
        // Toggle direction if same field
        newSorting[section] = {
          field,
          direction: prev[section].direction === 'asc' ? 'desc' :
                     prev[section].direction === 'desc' ? null : 'asc'
        };
      } else {
        // New field, start with ascending
        newSorting[section] = { field, direction: 'asc' };
      }
      return newSorting;
    });
  };

  const handleDismissClick = (event, prId) => {
    event.stopPropagation();

    if (openDismissDropdown === prId) {
      setOpenDismissDropdown(null);
      setActiveButtonId(null);
    } else {
      const buttonRect = event.currentTarget.getBoundingClientRect();
      setDropdownPosition({
        top: buttonRect.bottom,
        left: buttonRect.left
      });
      setOpenDismissDropdown(prId);
      setActiveButtonId(prId);
    }
  };

  const handleDismissOptionClick = (event, pr, option) => {
    event.stopPropagation(); // Prevent the click from bubbling up
    handleDismiss(pr, option);
  };

  const handleDismiss = (pr, option) => {
    const now = new Date();
    let until;

    switch (option) {
      case 'forever':
        until = 'forever';
        break;
      case 'until-update':
        until = 'until-update';
        break;
      case '1day':
        until = addDays(now, 1).toISOString();
        break;
      case '3days':
        until = addDays(now, 3).toISOString();
        break;
      case '7days':
        until = addDays(now, 7).toISOString();
        break;
      default:
        return;
    }

    setDismissedPRs(prev => ({
      ...prev,
      [pr.id]: {
        pr,
        dismissedAt: now.toISOString(),
        dismissedUntil: until,
        lastUpdateTime: pr.updatedAt
      }
    }));
    setOpenDismissDropdown(null);
  };

  const handleRestore = (prId) => {
    setDismissedPRs(prev => {
      const newDismissed = { ...prev };
      delete newDismissed[prId];
      return newDismissed;
    });
  };

  const isDismissed = (pr) => {
    const dismissal = dismissedPRs[pr.id];
    if (!dismissal) return false;

    if (dismissal.dismissedUntil === 'forever') return true;
    if (dismissal.dismissedUntil === 'until-update' && pr.updatedAt === dismissal.lastUpdateTime) return true;
    if (dismissal.dismissedUntil && new Date(dismissal.dismissedUntil) > new Date()) return true;

    // If we get here, the dismissal has expired
    handleRestore(pr.id);
    return false;
  };

  const renderPRTable = (prs, section, includeTeamColumn = false, includeLastReviewColumn = false) => {
    const sortConfig = sorting[section];
    const filteredPRs = getSortedPRs(prs, sortConfig)
      .filter(pr => !isDismissed(pr));

    if (filteredPRs.length === 0) {
      return <EmptyStateMessage>🎉 No PRs here!</EmptyStateMessage>;
    }

    return (
      <TableContainer className="table-container">
        <Table>
          <thead>
            <tr>
              <SortableHeader
                onClick={() => handleSort(section, 'repository')}
                data-sort-direction={sortConfig.field === 'repository' ? sortConfig.direction : null}
              >
                Repository
              </SortableHeader>
              <SortableHeader
                onClick={() => handleSort(section, 'title')}
                data-sort-direction={sortConfig.field === 'title' ? sortConfig.direction : null}
              >
                Title
              </SortableHeader>
              <SortableHeader
                onClick={() => handleSort(section, 'author')}
                data-sort-direction={sortConfig.field === 'author' ? sortConfig.direction : null}
              >
                Author
              </SortableHeader>
              {includeTeamColumn && (
                <SortableHeader
                  onClick={() => handleSort(section, 'team')}
                  data-sort-direction={sortConfig.field === 'team' ? sortConfig.direction : null}
                >
                  Team
                </SortableHeader>
              )}
              <SortableHeader
                onClick={() => handleSort(section, 'age')}
                data-sort-direction={sortConfig.field === 'age' ? sortConfig.direction : null}
              >
                Age
              </SortableHeader>
              <SortableHeader
                onClick={() => handleSort(section, 'updated')}
                data-sort-direction={sortConfig.field === 'updated' ? sortConfig.direction : null}
              >
                Last Updated
              </SortableHeader>
              <SortableHeader
                onClick={() => handleSort(section, 'comments')}
                data-sort-direction={sortConfig.field === 'comments' ? sortConfig.direction : null}
              >
                Comments
              </SortableHeader>
              <SortableHeader
                onClick={() => handleSort(section, 'unresolved')}
                data-sort-direction={sortConfig.field === 'unresolved' ? sortConfig.direction : null}
              >
                Unresolved
              </SortableHeader>
              {includeLastReviewColumn && (
                <SortableHeader
                  onClick={() => handleSort(section, 'lastReview')}
                  data-sort-direction={sortConfig.field === 'lastReview' ? sortConfig.direction : null}
                >
                  Your Last Review
                </SortableHeader>
              )}
              <Th>Action</Th>
            </tr>
          </thead>
          <tbody>
            {filteredPRs.map((pr) => (
              <tr key={pr.id}>
                <Td>{`${pr.repository.owner.login}/${pr.repository.name}`}</Td>
                <Td>
                  <PRLink href={pr.url} target="_blank" rel="noopener noreferrer">
                    {pr.title} (#{pr.number})
                  </PRLink>
                </Td>
                <Td>
                  <PRAuthor>{pr.author.login}</PRAuthor>
                </Td>
                {includeTeamColumn && (
                  <Td>
                    {pr.teamNames.join(', ')}
                  </Td>
                )}
                <Td>{formatDistanceToNow(new Date(pr.createdAt))} ago</Td>
                <Td>{formatDistanceToNow(new Date(pr.updatedAt))} ago</Td>
                <Td>
                  <CommentCount>
                    {pr.totalComments}
                  </CommentCount>
                </Td>
                <Td>{pr.unresolvedThreads}</Td>
                {includeLastReviewColumn && <Td>{pr.lastReview}</Td>}
                <Td style={{ position: 'relative' }}>
                  <DismissButton
                    onClick={(e) => handleDismissClick(e, pr.id)}
                    className="dismiss-button"
                    data-open={openDismissDropdown === pr.id}
                    data-pr-id={pr.id}
                  >
                    Dismiss
                  </DismissButton>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    );
  };

  const handleRefresh = () => {
    fetchPRs();
  };

  const renderDismissedPRs = () => {
    const dismissedPRsList = Object.values(dismissedPRs)
      .filter(({ dismissedUntil, pr }) => {
        if (dismissedUntil === 'forever' || dismissedUntil === 'until-update') return true;
        const untilDate = new Date(dismissedUntil);
        const now = new Date();
        return untilDate > now;
      })
      .sort((a, b) => {
        const dateA = new Date(a.dismissedAt);
        const dateB = new Date(b.dismissedAt);
        if (dateB > dateA) return 1;
        if (dateB < dateA) return -1;
        return 0;
      });

    return (
      <PRSection>
        <CollapsibleHeader onClick={() => setIsDismissedSectionExpanded(!isDismissedSectionExpanded)}>
          <Caret data-expanded={isDismissedSectionExpanded.toString()}>▶</Caret>
          <h2>Dismissed Pull Requests ({dismissedPRsList.length})</h2>
        </CollapsibleHeader>
        <CollapsibleContent data-expanded={isDismissedSectionExpanded.toString()}>
          <TableContainer className="table-container">
            <Table>
              <thead>
                <tr>
                  <Th>Repository</Th>
                  <Th>Title</Th>
                  <Th>Author</Th>
                  <Th>Dismissed Until</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              <tbody>
                {dismissedPRsList.map(({ pr, dismissedUntil }) => (
                  <tr key={pr.id}>
                    <Td>{`${pr.repository.owner.login}/${pr.repository.name}`}</Td>
                    <Td>
                      <PRLink href={pr.url} target="_blank" rel="noopener noreferrer">
                        {pr.title} (#{pr.number})
                      </PRLink>
                    </Td>
                    <Td>
                      <PRAuthor>{pr.author.login}</PRAuthor>
                    </Td>
                    <Td>
                      {dismissedUntil === 'forever' ? 'Forever' :
                       dismissedUntil === 'until-update' ? 'Until updated' :
                       formatDistanceToNow(new Date(dismissedUntil)) + ' remaining'}
                    </Td>
                    <Td>
                      <RestoreButton
                        onClick={() => handleRestore(pr.id)}
                        data-pr-id={pr.id}
                        className="dismiss-button"
                      >
                        Restore
                      </RestoreButton>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </CollapsibleContent>
      </PRSection>
    );
  };

  // Helper function to find PR by ID
  const findPRById = (prId) => {
    if (!prId) return null;

    const allPRs = [
      ...(Array.isArray(prs.authored) ? prs.authored : []),
      ...(Array.isArray(prs.directReview) ? prs.directReview : []),
      ...(Array.isArray(prs.teamReview) ? prs.teamReview : []),
      ...(Array.isArray(prs.mentioned) ? prs.mentioned : [])
    ];

    // Use a type-safe approach to find the PR
    for (let i = 0; i < allPRs.length; i++) {
      /** @type {{ id: string }} */
      const pr = allPRs[i];
      if (pr && pr.id === prId) {
        return pr;
      }
    }

    return null;
  };

  if (!token) {
    return (
      <Container>
        <LoginButton onClick={handleLogin}>
          Login with GitHub
        </LoginButton>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>GitHub PR Dashboard</h1>
        <RefreshButton
          onClick={handleRefresh}
          disabled={loading}
          data-loading={loading}
        >
          <RefreshIcon /> {loading ? 'Refreshing...' : 'Refresh PRs'}
        </RefreshButton>
      </Header>

      {loading ? (
        <LoadingOverlay>
          <LoadingSpinner />
          <div>Fetching Pull Requests...</div>
        </LoadingOverlay>
      ) : (
        <>
          <PRSection>
            <h2>Your Pull Requests ({prs.authored.filter(pr => !isDismissed(pr)).length})</h2>
            {renderPRTable(prs.authored, 'authored')}
          </PRSection>

          <PRSection>
            <h2>Review Requested Directly ({prs.directReview.filter(pr => !isDismissed(pr)).length})</h2>
            {renderPRTable(prs.directReview, 'directReview', false, true)}
          </PRSection>

          <PRSection>
            <h2>Review Requested to Your Teams ({prs.teamReview.filter(pr => !isDismissed(pr)).length})</h2>
            {renderPRTable(prs.teamReview, 'teamReview', true, true)}
          </PRSection>

          <PRSection>
            <h2>Mentioned You ({prs.mentioned.filter(pr => !isDismissed(pr)).length})</h2>
            {renderPRTable(prs.mentioned, 'mentioned')}
          </PRSection>

          {renderDismissedPRs()}
        </>
      )}

      <DropdownPortal isOpen={openDismissDropdown !== null}>
        <DismissDropdownWrapper style={{ top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px` }}>
          <DismissDropdown onClick={e => e.stopPropagation()}>
            <DismissOption onClick={(e) => {
              const pr = findPRById(openDismissDropdown);
              if (pr) handleDismissOptionClick(e, pr, 'until-update');
            }}>
              Until next update
            </DismissOption>
            <DismissOption onClick={(e) => {
              const pr = findPRById(openDismissDropdown);
              if (pr) handleDismissOptionClick(e, pr, 'forever');
            }}>
              Forever
            </DismissOption>
            <DismissOption onClick={(e) => {
              const pr = findPRById(openDismissDropdown);
              if (pr) handleDismissOptionClick(e, pr, '1day');
            }}>
              For 1 day
            </DismissOption>
            <DismissOption onClick={(e) => {
              const pr = findPRById(openDismissDropdown);
              if (pr) handleDismissOptionClick(e, pr, '3days');
            }}>
              For 3 days
            </DismissOption>
            <DismissOption onClick={(e) => {
              const pr = findPRById(openDismissDropdown);
              if (pr) handleDismissOptionClick(e, pr, '7days');
            }}>
              For 7 days
            </DismissOption>
          </DismissDropdown>
        </DismissDropdownWrapper>
      </DropdownPortal>
    </Container>
  );
}

export default App;
